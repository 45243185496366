import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  LIMIT,
  offsetAtom,
  textSnapshotsCountSelector,
  textSnapshotsSelector,
} from './searchHistoryState';

const Pagination = () => {
  const [offset, setOffset] = useRecoilState(offsetAtom);
  const textSnapshotsCount = useRecoilValue(textSnapshotsCountSelector);
  const textSnapshots = useRecoilValue(textSnapshotsSelector);
  if (!textSnapshots || textSnapshots?.length === 0) {
    return;
  }
  return (
    <nav
      id="pagination"
      className="flex items-center justify-between py-3 mx-auto border-t border-gray-200 md:max-w-2xl grow sm:px-6"
      aria-label="Pagination"
    >
      <div className="block px-2">
        <p className="text-sm text-gray-700">
          Showing {offset + 1} to {offset + textSnapshots?.length} of{' '}
          {textSnapshotsCount} results
        </p>
      </div>
      <div className="flex justify-between flex-1 px-2 sm:justify-end">
        <button
          id="previous"
          disabled={offset <= 0}
          onClick={() => setOffset(offset - LIMIT)}
          className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          id="next"
          disabled={offset + LIMIT >= textSnapshotsCount}
          onClick={() => setOffset(offset + LIMIT)}
          className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Next
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
