import * as React from 'react';
import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import {
  pageParamsSelector,
  rrwebEventsForSession,
  textSnapshotSelector,
} from './searchHistoryState';
import Player from './Player';
import ResultCard from './ResultCard';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import { useParams, Link } from 'react-router-dom';

const SnapshotCard = (props: { textSnapshotId: number }) => {
  const snapshot = useRecoilValue(textSnapshotSelector(props.textSnapshotId));
  return <ResultCard clickable={false} item={snapshot} />;
};

const PlayerContents = (props: { textSnapshotId: number }) => {
  const events = useRecoilValue(rrwebEventsForSession(props?.textSnapshotId));
  if (!events.length) {
    throw 'No events';
  }
  // Find when the snapshot id we care about within the session starts.
  let startingOffset = 0;
  events.every((value) => {
    if (value.text_snapshot_id === props?.textSnapshotId) {
      const timestamp = (value.raw as any)['timestamp'] ?? null;
      if (timestamp) {
        startingOffset =
          (value.raw as any)['timestamp'] - (events[0].raw as any)['timestamp'];
        return false;
      }
    }
    return true;
  });
  return (
    <Player events={events.map((v) => v.raw)} startingOffset={startingOffset} />
  );
};

const Contents = () => {
  const playerSnapshotIdRaw = useParams<'playerSnapshotId'>();
  const playerSnapshotId = parseInt(playerSnapshotIdRaw.playerSnapshotId);
  const search = useRecoilValue(pageParamsSelector);
  return (
    <div className="font-sans">
      <div className="m-5">
        <Link to={`..${search}`}>
          <button className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
            <ArrowSmallLeftIcon className="w-4 h-4 mr-2" />
            Return to search
          </button>
        </Link>
      </div>
      <PlayerContents textSnapshotId={playerSnapshotId} />
      <SnapshotCard textSnapshotId={playerSnapshotId} />
    </div>
  );
};

export default function PlayerPage() {
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center h-96">
          <div className="block w-6 h-6 border-2 rounded-full border-t-indigo-300 animate-spin"></div>
        </div>
      }
    >
      <Contents />
    </Suspense>
  );
}
