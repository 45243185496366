import * as React from 'react';
import {
  VideoCameraIcon,
  VideoCameraSlashIcon,
} from '@heroicons/react/24/outline';

import { TextSnapshot } from '../../utils/supabase';

const PlayResult = (props: { textSnapshot: TextSnapshot }) => {
  return (
    <>
      {props.textSnapshot.has_recording ? (
        <p className="flex items-center order-first px-2 text-xs font-semibold leading-7 text-white bg-indigo-500 rounded-full cursor-pointer hover:bg-indigo-600">
          <VideoCameraIcon className="w-4 h-4 mr-2" />
          Recording
        </p>
      ) : (
        <p className="flex items-center order-first px-2 text-xs font-semibold leading-7 rounded-full text-slate-500 bg-slate-100">
          <VideoCameraSlashIcon className="w-4 h-4 mr-2" />
          No Recording
        </p>
      )}
    </>
  );
};

export default PlayResult;
