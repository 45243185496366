import * as React from 'react';
import { Suspense, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { SupabaseClient } from '@supabase/supabase-js';
import {
  supabaseSessionAtom,
  supabaseSessionLoadingAtom,
} from '../supabaseState';

function Inner(props: { client: SupabaseClient }) {
  const [supabaseSession, setSupabaseSession] =
    useRecoilState(supabaseSessionAtom);
  const [supabaseSessionLoading, setSupabaseSessionLoading] = useRecoilState(
    supabaseSessionLoadingAtom,
  );

  useEffect(() => {
    if (!supabaseSession) {
      setSupabaseSessionLoading(true);
      props.client.auth.getSession().then(({ data: { session }, error }) => {
        if (error) {
          console.error(error);
        }
        setSupabaseSession(session);
        setSupabaseSessionLoading(false);
      });
      props.client.auth.onAuthStateChange((_event, session) => {
        setSupabaseSession(session);
      });
    }
  }, [supabaseSession]);

  return <></>;
}

// Fills in the supabaseSessionAtom.
export default function SessionProvider(props: { client: SupabaseClient }) {
  return (
    <Suspense>
      <Inner {...props} />
    </Suspense>
  );
}
