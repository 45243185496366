import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <div className="max-w-4xl px-4 m-auto my-10 font-sans text-base leading-7 text-gray-700 md:px-8">
      <div className="my-10">
        <Link to={`..`}>
          <button className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
            <ArrowSmallLeftIcon className="w-4 h-4 mr-2" />
            Back
          </button>
        </Link>
      </div>
      <h2 className="font-semibold">What is Lexographer?</h2>
      <p className="my-10">
        Lexographer is built to help you find anything you've seen on the
        internet. It allows you to browse full browser sessions and search for
        any text that appeared on a page.
        Under the hood, it is a full-text search engine for your personal
        browsing history that works by capturing, storing, and indexing the
        complete text of every page you visit.
      </p>
      <p className="my-10">
        What was once on the tip of your tongue is now a quick search away.
      </p>
      <h2 className="font-semibold">How do you keep my data safe?</h2>
      <p className="my-10">
        Your data is yours. Lexographer will never sell or share your
        data it with third parties directly or indirectly.
      </p>
      <p className="my-10">
        Privacy and security are top priorities for the team, since
        one's browsing history is full of sensitive data. There's a few specific
        approaches we're taking to keep data safe:
        <ul className="list-disc list-inside">
          <li> All data are encrypted at rest and over the wire </li>
          <li> Postgres RLS is used to partition user data </li>
          <li> Password fields aren't captured by default </li>
          <li>
            Browsing on banking domains isn't captured by default. It's easy to
            toggle recording for any domain as well.
          </li>
          {/* <li>
            Offering self-hosting as well as cloud hosting so users can decide
            where their data is stored
          </li> */}
        </ul>
        {/* As for the teams use case, my plan is to enable that by having fine
        grained access controls. For instance, you could scope your teammate's
        access to particular URL's and configure exactly who could see updates
        (like the URL of a notion doc for instance.) But this something that I'm
        still exploring. */}
        {/* What makes this better than Replay? The Team features! */}
        {/* Still have questions? Feel free to ask in the Discord */}
      </p>
      {/* <h2 className="font-semibold">Who is Lexographer for?</h2> */}
      {/* <h2 className="font-semibold">Pricing?</h2> */}

      <h2 className="font-semibold">Why did you build this?</h2>
      <p className="my-10">
        The problem with the standard Chrome browsing history is that it only
        stores page titles, which often doesn't help when trying to find
        specific text. For example, your Chrome history won't show anything for
        "PCB" even if you visited{' '}
        <a
          href="https://en.wikipedia.org/wiki/Monotron"
          className="text-indigo-500"
        >
          https://en.wikipedia.org/wiki/Monotron
        </a>,
        because it doesn't show up in the page's title. Google search is great
        but only covers public internet content. It's super easy to lose track
        of work documents, social media posts, emails, etc. that Google can't
        index.
      </p>
      <p className="my-10">
        Initially, the team tried integrating with the search API's of different
        platforms like Notion, GSuite, Slack, etc., but it only works for a
        limited set of sites and requires slow OAuth for each user on each
        platform. Inspired by Fullstory and session replay analytics, we took a
        different approach when building Lexographer. Instead of using the
        search API of each site, we built an index of the text seen client-side.
        This, in addition to the full session replay, allows you to search and
        explore full browsing sessions even if the page was updated over time.
        Getting these data into users hands is important, especially since most
        sites already use some form of session replay (like Fullstory) for
        analytics.
      </p>
    </div>
  );
};
