import * as React from 'react';
import { Suspense, useEffect, useState } from 'react';

import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid';

import { useRecoilValue } from 'recoil';
import { searchQueryAtom, textSnapshotsSelector } from './searchHistoryState';

import Pagination from './Pagination';
import Form from './Form';
import PlayResult from './PlayResult';
import ResultCard from './ResultCard';
import UnLoggedInHeader from './UnLoggedInHeader';
import { isExtensionInstalledSelector } from './extensionState';

const Results = () => {
  const searchQuery = useRecoilValue(searchQueryAtom);
  const textSnapshots = useRecoilValue(textSnapshotsSelector);
  const isExtensionInstalled = useRecoilValue(isExtensionInstalledSelector);

  if (!!textSnapshots && textSnapshots?.length === 0 && searchQuery) {
    return (
      <div className="p-4 mx-auto my-20 rounded-md md:max-w-2xl md:ring-1 ring-indigo-100 bg-indigo-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <QuestionMarkCircleIcon
              className="w-5 h-5 text-indigo-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-indigo-800">
              No results for "{searchQuery}".
            </h3>
            <div className="mt-2 text-sm text-indigo-700">
              <p>
                This string wasn't found in your Lexographer browser history.
                Make sure you're browsing while logged in with the Lexographer
                chrome extension installed.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (
    !!textSnapshots &&
    textSnapshots?.length === 0 &&
    !searchQuery &&
    isExtensionInstalled
  ) {
    return (
      <div className="p-4 mx-auto my-20 rounded-md md:max-w-2xl md:ring-1 ring-green-100 bg-green-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="w-5 h-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <div className="text-sm text-green-700">
              <p>Start browsing to save your history.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (
    !!textSnapshots &&
    textSnapshots?.length === 0 &&
    !searchQuery &&
    !isExtensionInstalled
  ) {
    return (
      <a href="https://chrome.google.com/webstore/detail/lexographer/fonankidfeconaagmonjbecpbhcmgffe">
        <div className="p-4 mx-auto my-20 rounded-md md:max-w-2xl md:ring-1 ring-green-100 bg-green-50">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="w-5 h-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <div className="text-sm text-green-700">
                <p>
                  Start saving your history with the Lexographer desktop Chrome
                  extension
                </p>
              </div>
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex">
                  <button
                    type="button"
                    className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                  >
                    Install Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  }
  return (
    <>
      {textSnapshots?.map((item) => (
        <ResultCard key={item.id} clickable={item.has_recording} item={item}>
          <PlayResult textSnapshot={item} />
        </ResultCard>
      ))}
    </>
  );
};

const SearchPage = () => {
  const searchRef = React.useRef(null);
  const [focusInput, setFocusInput] = useState(false);

  const scrollToDemoClicked = () => {
    setFocusInput(true);
  };

  useEffect(() => {
    if (focusInput) {
      setFocusInput(false);
    }
  }, [focusInput]);

  return (
    <>
      <UnLoggedInHeader onScrollToDemoClicked={scrollToDemoClicked} />
      <div className="min-h-[300px]">
        <Form focusInput={focusInput} />
        <Suspense>
          <Results />
          <Pagination />
        </Suspense>
      </div>
    </>
  );
};

export default SearchPage;
