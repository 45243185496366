import { number, string } from '@recoiljs/refine';
import { atom, selector, selectorFamily } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';
import { sharedSupabase, TextSnapshot } from '../../utils/supabase';

export const LIMIT = 20;

// MARK: User Inputs

export const offsetAtom = atom({
  key: 'offsetAtom',
  default: 0,
  effects: [urlSyncEffect({ refine: number(), history: 'push' })],
});

export const searchQueryAtom = atom({
  key: 'searchQueryAtom',
  default: '',
  effects: [urlSyncEffect({ refine: string(), history: 'push' })],
});

//  MARK: Snapshot searching

export const textSnapshotsCountSelector = selector({
  key: 'textSnapshotsCountSelector',
  get: async ({ get }) => {
    const query = get(searchQueryAtom);
    if (!query) {
      // Allow exploring all sessions if no query.
      const { error, count } = await sharedSupabase
        .sharedLocal()
        .from('text_snapshot')
        .select('*', { count: 'exact', head: true })
        // Indicates it's a starting snapshot.
        .is('starting_snapshot', null);
      if (error) {
        throw error;
      }
      return count;
    } else {
      const { data, error } = await sharedSupabase
        .sharedLocal()
        .rpc('search_text_snapshots_count', {
          query,
        })
        .single();
      if (error) {
        throw error;
      }
      return data.count;
    }
  },
});

export const textSnapshotsSelector = selector({
  key: 'textSnapshotsSelector',
  get: async ({ get }) => {
    const query = get(searchQueryAtom);
    if (!query) {
      // Allow exploring all sessions if no query.
      const { data, error } = await sharedSupabase
        .sharedLocal()
        .from('text_snapshot')
        .select('id,url,title,created_at,has_recording')
        .order('created_at', { ascending: false })
        // Indicates it's a starting snapshot.
        .is('starting_snapshot', null)
        .range(get(offsetAtom), get(offsetAtom) + LIMIT - 1);
      if (error) {
        throw error;
      }
      return data as TextSnapshot[];
    } else {
      const { data, error } = await sharedSupabase
        .sharedLocal()
        .rpc('search_text_snapshots', {
          query,
          limit_param: LIMIT,
          offset_param: get(offsetAtom),
        });
      if (error) {
        throw error;
      }
      return data;
    }
  },
});

const directTextSnapshotSelector = selectorFamily({
  key: 'directTextSnapshotSelector',
  get: (textSnapshotId: number) => async () => {
    const { data, error } = await sharedSupabase
      .sharedLocal()
      .from('text_snapshot')
      .select('id,url,title,created_at,has_recording,starting_snapshot')
      .eq('id', textSnapshotId)
      .single();
    if (error) {
      throw error;
    }
    return data;
  },
});

export const textSnapshotSelector = selectorFamily({
  key: 'textSnapshotSelector',
  get:
    (textSnapshotId: number) =>
    async ({ get }) => {
      const snapshots = get(textSnapshotsSelector)?.filter(
        (v) => v.id === textSnapshotId,
      );
      // If the search results change over time, or the search query isn't
      // specified, then we may not get a result. In that case, just fetch the
      // text snapshot.
      if (!snapshots || !snapshots.length) {
        const snapshot = get(directTextSnapshotSelector(textSnapshotId));
        return snapshot;
      }
      return get(textSnapshotsSelector)?.filter(
        (v) => v.id === textSnapshotId,
      )[0];
    },
});

// MARK: Visual history

export const rrwebEventsForSession = selectorFamily({
  key: 'rrwebEventsForSession',
  get:
    (textSnapshotId: number) =>
    async ({ get }) => {
      // Starting_snapshot of of null means it's its own starting snapshot.
      const startingSnapshotId =
        get(directTextSnapshotSelector(textSnapshotId)).starting_snapshot ??
        textSnapshotId;
      const { data, error } = await sharedSupabase
        .sharedLocal()
        .from('rrweb_event_with_snapshot')
        .select('id,raw,text_snapshot_id,starting_snapshot')
        .eq('starting_snapshot', startingSnapshotId)
      // TODO: For some reason this causes 504s. No idea why.
      // .order('id', { ascending: true })

      // Add a sort to create the sorting clientside.
      data.sort((a, b) => a.id - b.id);

      if (error) {
        throw error;
      }
      return data;
    },
});

export const pageParamsSelector = selector({
  key: 'pageParamsSelector',
  get: async ({ get }) => {
    const query = get(searchQueryAtom);
    const offset = get(offsetAtom);
    const ret = [];
    // TODO: This is jank. We should be either constructing the query from
    // RecoilURL or maybe just pull it all from router.
    if (query != null) {
      ret.push(
        `${encodeURIComponent(searchQueryAtom.key)}="${encodeURIComponent(
          query,
        )}"`,
      );
    }
    if (offset != null) {
      ret.push(
        `${encodeURIComponent(offsetAtom.key)}=${encodeURIComponent(offset)}`,
      );
    }
    return '?' + ret.join('&');
  },
});
