import { Session } from '@supabase/supabase-js';
import { atom } from 'recoil';

// I would love to store the supabase client in recoil, but it becomes
// immutable, so it can't be extended within to the library.

// Atom that reflects the supabase session.
export const supabaseSessionAtom = atom<Session>({
    key: 'supabaseSessionAtom',
    default: null,
});

export const supabaseSessionLoadingAtom = atom<boolean>({
    key: 'supabaseSessionLoadingAtom',
    default: true,
});
