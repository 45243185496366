import * as React from 'react';
import { Auth } from '@supabase/auth-ui-react';
import {
  supabaseSessionAtom,
  supabaseSessionLoadingAtom,
} from '../supabaseState';
import { useRecoilValue } from 'recoil';
import { SupabaseClient } from '@supabase/supabase-js';

const Contents = (props: {
  client: SupabaseClient;
  view: 'sign_in' | 'sign_up' | 'update_password';
}) => {
  const supabaseSession = useRecoilValue(supabaseSessionAtom);
  if (props.view !== 'update_password' && supabaseSession?.user) {
    return (
      <div>
        <p className="my-10 text-sm font-medium text-center text-gray-700">
          You're signed in as {supabaseSession?.user?.email}.
        </p>
        {/* Use the prod link here because this component is used in the extension context. */}
        <a href="https://lexographer.com" target="_blank">
          <button className="w-full px-4 py-2 mb-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            {props.view === 'sign_up' ? 'Continue' : 'Return to search'}
          </button>
        </a>
        <button
          className="w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          onClick={() => {
            props.client.auth.signOut();
          }}
        >
          Sign out
        </button>
      </div>
    );
  }
  if (props.view === 'update_password') {
    props.client.auth.onAuthStateChange((event, session) => {
      if (event === 'USER_UPDATED') {
        window.location.href = '/';
      }
    });
  }
  return (
    <Auth
      supabaseClient={props.client}
      appearance={{
        variables: {
          default: {
            colors: {
              brandAccent: 'rgb(67 56 202)',
            },
          },
        },
        className: {
          anchor:
            'mt-4 font-medium text-sm text-indigo-600 hover:text-indigo-500',
          button:
            'rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
          // container?: string | CssComponent;
          // divider?: string | CssComponent;
          input:
            'p-2 mt-2 text-sm placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:border-indigo-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500',
          label: 'mt-2 block text-sm font-medium text-gray-700',
          loader:
            'block w-6 h-6 border-2 rounded-full border-t-indigo-300 animate-spin', // what does this do
          message:
            'border-l-4 border-yellow-400 rounded-md bg-yellow-50 p-4 text-yellow-700 text-sm font-medium',
        },
      }}
      redirectTo="https://lexographer.com/update_password"
      view={props.view}
    />
  );
};
export default function LoginPage(props: {
  client: SupabaseClient;
  view: 'sign_in' | 'sign_up' | 'update_password';
}) {
  const loading = useRecoilValue(supabaseSessionLoadingAtom);
  return (
    <div className="flex h-screen px-2">
      {loading ? (
        <span className="block w-6 h-6 m-auto border-4 rounded-full border-t-indigo-300 animate-spin"></span>
      ) : (
        <div className="p-8 m-auto bg-white rounded-md shadow min-x w-96">
          <Contents client={props.client} view={props.view} />
        </div>
      )}
    </div>
  );
}
