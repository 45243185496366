import * as React from 'react';
import { createRoot } from "react-dom/client";
import { RecoilRoot } from 'recoil';
// This line apparently is what tells webpack to create the css file
import './styles/searchPage.scss'
import { PROJECT_ROOT } from "./utils/constants";
import { RecoilURLSyncJSON } from "recoil-sync";
import SearchRoutes from "./components/page/SearchRoutes";
import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: 'https://app.posthog.com',
}

const container = document.createElement('div');
document.getElementById(PROJECT_ROOT).appendChild(container);
createRoot(container).render(
  <RecoilRoot>
    <PostHogProvider
      apiKey={'phc_WqNark9r5ZDnWJyPaztQON1AJLjGlxJN3o3B7EpdWpO'}
      options={options}
    >
      <RecoilURLSyncJSON location={{ part: 'queryParams' }}>
      <SearchRoutes />
    </RecoilURLSyncJSON>

    </PostHogProvider>
  </RecoilRoot>,
);
