import { createClient, SupabaseClient } from '@supabase/supabase-js';
import type { Database } from './DatabaseDefinitions';
import {
  Storage as ExtensionStorage,
  getStorageItem,
  setStorageItem,
} from '../storage';

// Generate database definitions using
// supabase db remote commit
// supabsae db reset
// supabase gen types typescript --local > src/utils/DatabaseDefinitions.ts

// MARK: - Supabase function type definitions

// For some reason, supabase doesn't infer the types of functions that return tables.
export type TextSnapshot = {
  id: number;
  title: string;
  created_at: string;
  url: string;
  highlight?: string;
  has_recording?: boolean | null;
};

interface FunctionAnnotated {
  public: {
    Functions: {
      search_text_snapshots: {
        Returns: TextSnapshot;
      };
      search_text_snapshots_count: {
        Returns: {
          count: number;
        };
      };
    };
  };
}

type Combined = Database & FunctionAnnotated;

const supabaseUrl = 'https://cdwmgjbgjcptjxkknesj.supabase.co';
// This is safe to share publically, since it only allows anonymous access
const supabaseAnonKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNkd21namJnamNwdGp4a2tuZXNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjQ3NDAxNjEsImV4cCI6MTk4MDMxNjE2MX0.u7qWYvnC0O-pLZTNtpN8RvTIEzm9X0EqDeYghfBwOL0';

export const ClientType = createClient<Combined>;

// Implement a singleton for supabase so that the same instance is shared. This
// is necessary so we can listen to updates of the session object.
class SharedSupabase {
  #localStoragePersisted: SupabaseClient;
  #chromeStoragePersisted: SupabaseClient;
  #popupStoragePersisted: SupabaseClient;
  sharedLocal(): ReturnType<typeof ClientType> {
    if (!this.#localStoragePersisted) {
      this.#localStoragePersisted = createClient<Combined>(
        supabaseUrl,
        supabaseAnonKey,
        {
          auth: {
            storageKey: 'supabaseAuthToken',
            storage: {
              getItem: async (key: keyof ExtensionStorage) => {
                const localStorageSession = localStorage.getItem(key);
                return localStorageSession
              },
              setItem: async (key: keyof ExtensionStorage, value) => {
                return localStorage.setItem(key, value);
              },
              removeItem: async (key: keyof ExtensionStorage) => {
                return localStorage.removeItem(key);
              },
            },
          },
        }
      );
    }
    return this.#localStoragePersisted;
  }

  sharedPopup(): ReturnType<typeof ClientType> {
    if (!this.#popupStoragePersisted) {
      this.#popupStoragePersisted = createClient<Combined>(
        supabaseUrl,
        supabaseAnonKey,
        {
          auth: {
            storageKey: 'supabaseAuthToken',
            autoRefreshToken: true,
            storage: {
              getItem: (key: keyof ExtensionStorage) => {
                return getStorageItem(key);
              },
              setItem: (key: keyof ExtensionStorage, value) => {
                return setStorageItem(key, value);
              },
              removeItem: (key: keyof ExtensionStorage) => {
                return setStorageItem(key, null);
              },
            },
          },
        },
      );
    }
    return this.#popupStoragePersisted;
  }
  
  sharedChrome(): ReturnType<typeof ClientType> {
    if (!this.#chromeStoragePersisted) {
      this.#chromeStoragePersisted = createClient<Combined>(
        supabaseUrl,
        supabaseAnonKey,
        {
          auth: {
            storageKey: 'supabaseAuthToken',
            autoRefreshToken: true,
            storage: {
              getItem: (key: keyof ExtensionStorage) => {
                return getStorageItem(key);
              },
              setItem: (key: keyof ExtensionStorage, value) => {
                return setStorageItem(key, value);
              },
              removeItem: (key: keyof ExtensionStorage) => {
                return setStorageItem(key, null);
              },
            },
          },
        },
      );
    }
    return this.#chromeStoragePersisted;
  }
}

export const sharedSupabase = new SharedSupabase()