import { atom, selector } from 'recoil';

export const extensionId: string =
  process.env.NODE_ENV !== 'development'
    ? 'fonankidfeconaagmonjbecpbhcmgffe'
    : 'ccnccillpkglbhjjmhmhdchhedgjacbn';

export const extensionInstalledAtom = atom<string | null>({
  key: 'extensionInstalledAtom',
  // null means unknown
  // '' means no extension installed
  default: null,
});

export const isExtensionInstalledSelector = selector({
  key: 'isExtensionInstalledSelector',
  get: async ({ get }) => {
    const extesionInstalled = get(extensionInstalledAtom);
    return !!(extesionInstalled === null || extesionInstalled);
  },
});
