import * as React from 'react';
import { MouseEvent, ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { NavigateMessage } from '../../utils/navigate';
import { pageParamsSelector, searchQueryAtom } from './searchHistoryState';
import { TextSnapshot } from '../../utils/supabase';
import { extensionInstalledAtom } from './extensionState';
import styled from 'styled-components';

const TwoLineUrl = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ResultCard = (props: {
  item: TextSnapshot;
  clickable: boolean;
  children?: ReactNode;
}) => {
  const item = props.item;
  if (!item) {
    return;
  }
  const search = useRecoilValue(pageParamsSelector);
  const extensionInstalled = useRecoilValue(extensionInstalledAtom);
  const searchQuery = useRecoilValue(searchQueryAtom);
  const foundStringPre = item.highlight && item.highlight.split('<b>')[1];
  // TODO: doesn't work for multi word query
  let foundString: string;
  if (foundStringPre) {
    foundString = foundStringPre.split('</b>')[0];
  }
  if (!foundString) {
    foundString = searchQuery;
  }

  const onClickUrl = (event: MouseEvent<HTMLDivElement>) => {
    // Hack to prevent the card from being clicked when the link is clicked.
    event.stopPropagation();
    // If the extension is not installed, open the link in a new tab.
    if (!extensionInstalled) {
      window.open(item.url);
      return;
    }
    const message: NavigateMessage = {
      type: 'navigate',
      url: item.url,
      lastQuery: foundString,
    };
    window.postMessage(message, '*');
  };

  const onClickCard = () => {
    if (!props.clickable) {
      return;
    }
    window.location.href = `/player-${item.id}${search}`;
  };

  return (
    <div className="md:mx-2">
      <div
        className={`mx-auto my-6 text-sm break-words bg-white md:max-w-2xl grow md:shadow-md text-slate-700 md:rounded-xl md:ring-1 ring-slate-900/5 ${
          props.clickable && 'cursor-pointer'
        }`}
        onClick={onClickCard}
      >
        <div className="p-4 md:p-8">
          <div className="cursor-pointer" onClick={onClickUrl}>
            <TwoLineUrl className="my-2 overflow-hidden text-xs leading-tight text-slate-500 hover:underline">
              {item.url}
            </TwoLineUrl>
            <p className="my-2 text-lg leading-tight text-indigo-500 hover:text-indigo-600">
              {item.title}
            </p>
          </div>
          {/* We use this to get the bolding passed from the highlight. */}
          {item.highlight && (
            <p
              dangerouslySetInnerHTML={{ __html: item.highlight }}
              className="my-2"
            />
          )}
          <div className="text-xs uppercase text-slate-500">
            {new Date(item.created_at).toLocaleString('en-us')}
          </div>
          <div className="flex justify-end my-2">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default ResultCard;
