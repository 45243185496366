export type ExtensionRequest = {
  message: string;
};

export type ExtensionVersionRequest = {
  message: 'version';
};

export type ExtensionMessageResponse = {
  version?: string;
  session?: string;
};

// Send message to chrome extension async
export const sendMessageToExtension = async <T extends ExtensionRequest>(
  id: string,
  request: T,
): Promise<ExtensionMessageResponse> => {
  return new Promise((resolve, reject) => {
    if (typeof chrome === 'undefined' || !chrome?.runtime?.sendMessage) {
      reject('No chrome runtime');
    }
    chrome.runtime.sendMessage(id, request, (response) => {
      const lastError = chrome.runtime.lastError;
      if (lastError) {
        reject(lastError);
      } else {
        resolve(response);
      }
    });
  });
};
