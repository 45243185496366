import * as React from 'react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import {
  sendMessageToExtension,
} from '../../utils/worker';
import { extensionId, extensionInstalledAtom } from './extensionState';

export default function ExtensionStateProvider() {
  const [_, setExtensioninstalled] = useRecoilState(extensionInstalledAtom);
  useEffect(() => {
    if (typeof chrome === 'undefined' || !chrome?.runtime?.sendMessage) {
      setExtensioninstalled('');
      return;
    }
    [extensionId].forEach(async (id) => {
      try {
        const response = await sendMessageToExtension(id, {
          message: 'version',
        });
        if (response?.version) {
          setExtensioninstalled(id);
        }
      } catch (e) {
        // Ignore since this is expected.
      }
    });
  }, []);

  if (process.env.NODE_ENV === 'development') {
    return (
      <div className="fixed bottom-0 right-0 flex items-center order-first px-5 m-2 text-xs font-semibold leading-7 rounded-full text-slate-500 bg-slate-100">
        Dev Mode: {extensionId}
      </div>
    );
  }

  return <></>;
}
