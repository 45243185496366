import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  supabaseSessionAtom,
  supabaseSessionLoadingAtom,
} from '../supabaseState';
import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  CircleStackIcon,
  FolderArrowDownIcon,
  LockClosedIcon,
  MagnifyingGlassIcon,
  PlayIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const features = [
  {
    name: 'Powerful search',
    imageElement: <MagnifyingGlassIcon className="w-6 h-6 text-indigo-100" />,
    description:
      "Semantic search helps you find exactly what you're looking for and answer questions about what you've seen.",
  },
  {
    name: 'Full session replay',
    imageElement: <PlayIcon className="w-6 h-6 text-indigo-100" />,
    description:
      'Play sessions to see the visual context of the page you were on. Never be suprised by the content of a page changing.',
  },
  {
    name: 'Privacy first',
    imageElement: <LockClosedIcon className="w-6 h-6 text-indigo-100" />,
    description:
      'Lexographer will never sell or share your data it with third parties.',
  },
];

const howItWorks = [
  {
    name: 'Chrome extension',
    imageElement: (
      <img className="w-auto h-5 mx-auto -my-1" src="/icons/Chrome-Logo.png" />
    ),
    description: (
      <p>
        Nothing to run locally. Simply{' '}
        <a
          className="text-blue-600"
          href="https://chrome.google.com/webstore/detail/lexographer/fonankidfeconaagmonjbecpbhcmgffe"
        >
          install the Chrome Extension
        </a>{' '}
        and start browsing. Support for other browswers coming soon.
      </p>
    ),
  },
  {
    name: 'Automagically save everything',
    imageElement: <FolderArrowDownIcon className="w-6 h-6 text-indigo-100" />,
    description:
      "Saves all text, images and dynamic content of every page you visit. Easily exclude domains you don't want to record.",
  },
  {
    name: 'Query the full-text search engine',
    imageElement: <CircleStackIcon className="w-6 h-6 text-indigo-100" />,
    description:
      "Ask natural language questions and use flexible filters to find what you were looking for, even if  you don't remember all the details.",
  },
];

const ValuePropSection = (props: {
  title: string;
  features: {
    name: string;
    imageElement: JSX.Element;
    description: JSX.Element | string;
  }[];
}) => {
  return (
    <section
      aria-labelledby="perks-heading"
      className="m-4 rounded-lg bg-gray-100"
    >
      <h2 id="perks-heading" className="sr-only">
        {props.title}
      </h2>

      <div className="px-4 py-24 mx-auto max-w-7xl sm:px-6 sm:py-32 lg:px-8">
        <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-3 sm:gap-x-6">
          {props.features.map((perk) => (
            <div key={perk.name} className="">
              <div className="md:flex-shrink-0">
                <div className="flow-root">
                  <div className="w-12 h-12 rounded-lg flex items-center justify-center bg-indigo-600">
                    {perk.imageElement}
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <h3 className="text-base font-medium text-gray-900">
                  {perk.name}
                </h3>
                <p className="mt-3 text-sm text-gray-500">{perk.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default function LoginBanner(props: {
  onScrollToDemoClicked: () => void;
}) {
  const [showBanner, setShowBanner] = useState(true);
  const supabaseSession = useRecoilValue(supabaseSessionAtom);
  const supabaseSessionLoading = useRecoilValue(supabaseSessionLoadingAtom);
  if (supabaseSession?.user || !showBanner || supabaseSessionLoading) {
    return;
  }
  return (
    <div className="bg-gray-50">
      <div className="bg-indigo-50 rounded-lg m-4 ">
        <div className="max-w-7xl mx-auto px-6 py-12 lg:flex lg:items-center lg:justify-between lg:py-24 lg:px-8">
          <div>
            <h2 className="text-3xl font-bold font-serif tracking-tight text-gray-900 lg:mr-10 md:text-4xl">
              Explore your full browser history with Lexographer
            </h2>
            <p className="mt-4 text-base leading-7 text-gray-600 lg:mr-10">
              Browse full sessions of your browser history. Search for any text
              that appeared on a page.{' '}
              <Link className="text-blue-600" to="/signup">
                Sign up
              </Link>{' '}
              and{' '}
              <a
                className="text-blue-600"
                href="https://chrome.google.com/webstore/detail/lexographer/fonankidfeconaagmonjbecpbhcmgffe"
              >
                install the Chrome Extension
              </a>{' '}
              to get started, or check out a demo below.
            </p>
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                <a href="/faq">
                  <button
                    type="button"
                    className="rounded-md bg-indigo-50 px-2 py-1.5 text-sm font-medium text-indigo-800 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-indigo-50"
                  >
                    Learn More
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="flex mt-8 lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              {/* TODO Waitlist signup link */}
              <a href="/signup">
                <div className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700">
                  Alpha sign up
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col my-20 items-center justify-center space-y-2 animate-fadeIn">
        <button
          onClick={props.onScrollToDemoClicked}
          className="px-6 py-2 text-lg font-medium text-center text-indigo-700 rounded-full hover:bg-indigo-100 bg-indigo-50 ring-1 ring-inset ring-indigo-700/10"
        >
          Try a demo
        </button>
        <ChevronDownIcon className="w-5 h-5 animate-bounce" />
      </div>

      <ValuePropSection title="Feature" features={features} />
      <ValuePropSection title="How it works" features={howItWorks} />
    </div>
  );
}
