import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  offsetAtom,
  searchQueryAtom,
  textSnapshotsCountSelector,
  textSnapshotsSelector,
} from './searchHistoryState';
import {
  supabaseSessionAtom,
  supabaseSessionLoadingAtom,
} from '../supabaseState';

const Form = (props: { focusInput: boolean }) => {
  const formRef = useRef(null);
  const [formQuery, setFormQuery] = useState('');
  const [searchQuery, setSearchQuery] = useRecoilState(searchQueryAtom);
  const [, setOffset] = useRecoilState(offsetAtom);
  const textSnapshotsCountLoadable = useRecoilValueLoadable(
    textSnapshotsCountSelector,
  );
  const textSnapshotsLoadable = useRecoilValueLoadable(textSnapshotsSelector);
  const searchLoading =
    textSnapshotsCountLoadable.state === 'loading' ||
    textSnapshotsLoadable.state === 'loading';
  const supabaseSession = useRecoilValue(supabaseSessionAtom);
  const supabaseSessionLoading = useRecoilValue(supabaseSessionLoadingAtom);

  const loggedIn = supabaseSessionLoading || supabaseSession?.user;

  useEffect(() => {
    if (formRef.current && loggedIn) {
      formRef.current.focus();
    }
  }, []);

  if (props.focusInput) {
    formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setTimeout(() => {
      formRef.current.focus();
    }, 1000);
  }

  return (
    <div className="md:mx-2">
      <form
        className={`px-2 pt-12 pb-6 mx-auto md:max-w-2xl grow md:px-0`}
        onSubmit={async (e) => {
          e.preventDefault();
          setOffset(0);
          setSearchQuery(formQuery);
        }}
      >
        <label
          htmlFor="search"
          className="block text-sm font-medium text-gray-700"
        >
          {loggedIn ? <p>Search Your Sessions</p> : <p>Search Demo Sessions</p>}
        </label>
        <div className="relative flex items-center mt-1">
          <input
            type="text"
            name="search"
            id="searchInput"
            ref={formRef}
            placeholder={supabaseSession?.user ? '' : 'Try searching "frog"'}
            defaultValue={searchQuery}
            onChange={(e) => setFormQuery(e.target.value)}
            className="block w-full pr-12 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            {searchLoading ? (
              <div
                className="flex items-center gap-2 px-2 text-gray-500"
                id="searchSpinner"
              >
                <span className="block w-6 h-6 border-4 rounded-full border-t-indigo-300 animate-spin"></span>
              </div>
            ) : (
              <input
                id="searchButton"
                type="submit"
                className="inline-flex items-center px-2 font-sans text-sm font-medium text-gray-400 border border-gray-200 rounded hover:bg-gray-50"
                value="↵"
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
