import * as React from 'react';
import PlayerPage from './PlayerPage';
import SearchPage from './SearchPage';

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  useRouteError,
} from 'react-router-dom';
import LoginPage from './LoginPage';
import SessionProvider from './SessionProvider';
import { sharedSupabase } from '../../utils/supabase';
import RootRoute from './RootRoute';
import ExtensionStateProvider from './ExtensionStateProvider';
import FAQPage from './FAQPage';
import DomainConfig from './DomainConfig';

const ErrorElement = () => {
  const error = useRouteError();
  console.error(error);
  return (
    <div className="text-2xl text-center text-gray-700 my-60">
      Error found when loading the page. Please contact support if this problem
      persists.
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<RootRoute />} errorElement={<ErrorElement />}>
        <Route path="/" element={<SearchPage />} />
        {/* Need to use hyphen here due to a strange serving issue.
          https://stackoverflow.com/questions/74533285/react-router-404-for-bundle
        */}
        <Route path="/player-:playerSnapshotId" element={<PlayerPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/domains" element={<DomainConfig />} />
      </Route>
      <Route
        path="/login"
        element={<LoginPage client={sharedSupabase.sharedLocal()} view="sign_in" />}
      />
      <Route
        path="/signup"
        element={<LoginPage client={sharedSupabase.sharedLocal()} view="sign_up" />}
      />
      <Route
        path="/update_password"
        element={<LoginPage client={sharedSupabase.sharedLocal()} view="update_password" />}
      />
    </>,
  ),
);

export default function SearchRoutes() {
  return (
    <div className="font-sans">
      <SessionProvider client={sharedSupabase.sharedLocal()} />
      <ExtensionStateProvider />
      <RouterProvider router={router} />
    </div>
  );
}
