import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <div className="max-w-4xl px-4 m-auto my-10 font-sans text-base leading-7 text-gray-700 md:px-8">
      <div className="my-10">
        <Link to={`..`}>
          <button className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
            <ArrowSmallLeftIcon className="w-4 h-4 mr-2" />
            Back
          </button>
        </Link>
      </div>
    </div>
  );
};
