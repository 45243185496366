import * as React from 'react';
import { useEffect, useState, useRef, FC, useLayoutEffect } from 'react';
import rrwebPlayer from 'rrweb-player';
import { Json } from '../../utils/DatabaseDefinitions';

type PlayerProps = {
  events: Json[];
  startingOffset: number;
  loop?: boolean;
};

const Player: FC<PlayerProps> = (props) => {
  const rrwebEvents = props?.events;
  const playerRef = useRef<HTMLDivElement>();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (playerRef.current) {
      setDimensions({
        width: playerRef.current.offsetWidth,
        height: playerRef.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (!rrwebEvents) {
      return;
    }
    if (rrwebEvents.length < 2) {
      return;
    }
    if (!playerRef) {
      return;
    }
    if (dimensions.width === 0) {
      return;
    }
    // Make a deep copy becuase of `Uncaught TypeError: Cannot add property
    // delay, object is not extensible`
    let events = JSON.parse(JSON.stringify(rrwebEvents));
    const replayer = new rrwebPlayer({
      target: playerRef.current,
      props: {
        events,
        // TODO have this size properly
        height: 400,
        width: dimensions.width,
        mouseTail: false,
      },
    });
    replayer.play();
    replayer.goto(props?.startingOffset);
    if (props?.loop) {
      replayer.addEventListener('finish', () => {
        replayer.play();
      });
    }
  }, [dimensions, rrwebEvents]);

  return <div className="z-0 inline-block w-full" ref={playerRef}></div>;
};

export default Player;
